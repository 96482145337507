require('./src/global.css')

const addScript = url => {
  const script = document.createElement('script')
  script.src = url
  document.getElementsByTagName(`body`)[0].appendChild(script)
}

const addContentsquare = () => {
  // Check if Contentsquare is already initialized
  if (window.hj) return
  ;(function (c, s, q, u, a, r, e) {
    c.hj =
      c.hj ||
      function () {
        ;(c.hj.q = c.hj.q || []).push(arguments)
      }
    c._hjSettings = { hjid: a }
    r = s.getElementsByTagName('head')[0]
    e = s.createElement('script')
    e.async = true
    e.src = q + c._hjSettings.hjid + u
    r.appendChild(e)
  })(window, document, 'https://static.hj.contentsquare.net/c/csq-', '.js', 5350406)
}

// eslint-disable-next-line no-unused-vars
exports.onRouteUpdate = s => {
  addContentsquare()
  addScript(`//scripts.iconnode.com/${process.env.GATSBY_WHATCONVERTS_NUMBER}.js`)
  addScript(process.env.GATSBY_BIRDEYE_REVIEW_URL)
}
